import { createSelector } from "@reduxjs/toolkit";
import createActivitiesPerDateSelector from "store/selectors/createActivitiesPerDateSelector";

const selectTodayActivities = createSelector(
  createActivitiesPerDateSelector(new Date().toISOString()),
  (activities) => {
    return activities;
  }
);

export default selectTodayActivities;
