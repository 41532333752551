import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TimeBlock } from "types";

interface HoveredTimeBlock extends TimeBlock {
  activityId: string;
}

interface HoveredLegendState {
  hoveredTimeBlock: HoveredTimeBlock | null;
}

const initialState: HoveredLegendState = {
  hoveredTimeBlock: null,
};

const hoveredLegendSlice = createSlice({
  name: "hoveredLegend",
  initialState,
  reducers: {
    setHoveredTimeBlock: (
      state,
      action: PayloadAction<HoveredTimeBlock | null>
    ) => {
      state.hoveredTimeBlock = action.payload;
    },
  },
});

export const { setHoveredTimeBlock } = hoveredLegendSlice.actions;
export default hoveredLegendSlice.reducer;
