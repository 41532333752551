import { createAsyncThunk } from "@reduxjs/toolkit";
import { parseISO, startOfDay } from "date-fns";
import { RootState } from "store";
import { addActivity, updateActivity } from "store/activities";
import { addActivityType } from "store/activityType";
import { Activity, ActivityType, TimeBlock } from "types";
import {
  consolidateTimeBlocks,
  updateActivitiesTimeBlocks,
} from "utils/activities";

const isSameDay = (date1: string, date2: Date): boolean => {
  const parsedDate1 = parseISO(date1);
  return startOfDay(parsedDate1).getTime() === startOfDay(date2).getTime();
};

export const createActivityWithType = createAsyncThunk<
  void,
  {
    activityType: Omit<ActivityType, "id" | "archived">;
    timeBlocks: TimeBlock[];
  },
  {
    state: RootState;
  }
>(
  "activities/createWithType",
  async ({ activityType, timeBlocks }, { dispatch, getState }) => {
    const state = getState();

    // Check if an activity type with the same title already exists
    const existingActivityType = state.activityTypes.list.find(
      (type) => type.title.toLowerCase() === activityType.title.toLowerCase()
    );

    let activityTypeId: string;

    if (existingActivityType) {
      activityTypeId = existingActivityType.id;
    } else {
      const resultAction = await dispatch(addActivityType(activityType));
      if (addActivityType.fulfilled.match(resultAction)) {
        activityTypeId = resultAction.payload.id;
      } else {
        throw new Error("Failed to create activity type");
      }
    }

    // Check if an activity for this type already exists for the current day
    const today = new Date();
    const existingActivity = state.activities.list.find(
      (activity) =>
        activity.typeId === activityTypeId &&
        activity.timeBlocks.some((block) => isSameDay(block.start, today))
    );

    if (existingActivity) {
      // Merge the new time blocks with the existing ones
      const mergedTimeBlocks = consolidateTimeBlocks([
        ...existingActivity.timeBlocks,
        ...timeBlocks,
      ]);

      // Update the existing activity
      const updatedActivity = {
        ...existingActivity,
        timeBlocks: mergedTimeBlocks,
      };

      // Adjust other activities' time blocks to avoid overlaps
      const otherActivities = state.activities.list.filter(
        (a) => a.id !== existingActivity.id
      );
      const updatedActivities = updateActivitiesTimeBlocks(
        otherActivities,
        updatedActivity
      );

      // Dispatch update for all affected activities
      updatedActivities.forEach((activity) => {
        dispatch(updateActivity(activity));
      });
    } else {
      // Create a new activity
      const newActivity: Omit<Activity, "id"> = {
        typeId: activityTypeId,
        timeBlocks: consolidateTimeBlocks(timeBlocks),
      };

      // Add the new activity, which will automatically adjust other activities
      dispatch(addActivity(newActivity));
    }
  }
);
