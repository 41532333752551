import { configureStore } from "@reduxjs/toolkit";
import activitiesReducer, { resetActivities } from "store/activities";
import activityTypeReducer, { resetActivityTypes } from "store/activityType";
import blockSizeReducer, { resetBlockSize } from "store/blockSize";
import hoveredLegendReducer from "store/hoveredLegend";
import {
  filterActivitiesForToday,
  loadActivities,
  loadActivityTypes,
  loadBlockSize,
  loadTimeFormat,
} from "utils/localStorage";
import { getDefaultTimeFormat } from "utils/time";

const store = configureStore({
  reducer: {
    activities: activitiesReducer,
    activityTypes: activityTypeReducer,
    blockSize: blockSizeReducer,
    hoveredLegend: hoveredLegendReducer,
  },
  preloadedState: {
    activities: { list: filterActivitiesForToday(loadActivities()) },
    activityTypes: { list: loadActivityTypes() },
    blockSize: {
      minutes: loadBlockSize() || 30,
      timeFormat: loadTimeFormat() || getDefaultTimeFormat(),
    },
  },
});

export const resetStore = (dispatch: AppDispatch) => {
  dispatch(resetActivities());
  dispatch(resetActivityTypes());
  dispatch(resetBlockSize());
};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
