import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Activity } from "types";
import {
  consolidateTimeBlocks,
  updateActivitiesTimeBlocks,
} from "utils/activities";
import { saveActivities } from "utils/localStorage";
import { v4 as uuidv4 } from "uuid";

interface ActivitiesState {
  list: Activity[];
}

const initialState: ActivitiesState = {
  list: [],
};

const activitiesSlice = createSlice({
  name: "activities",
  initialState,
  reducers: {
    resetActivities: () => {
      saveActivities([]);
      return initialState;
    },

    addActivity: (state, action: PayloadAction<Omit<Activity, "id">>) => {
      const newActivity: Activity = {
        id: uuidv4(),
        ...action.payload,
        timeBlocks: consolidateTimeBlocks(action.payload.timeBlocks),
      };
      state.list = updateActivitiesTimeBlocks(state.list, newActivity);
      saveActivities(state.list);
    },

    updateActivity: (state, action: PayloadAction<Activity>) => {
      const updatedActivity = {
        ...action.payload,
        timeBlocks: consolidateTimeBlocks(action.payload.timeBlocks),
      };
      state.list = updateActivitiesTimeBlocks(
        state.list.filter((activity) => activity.id !== updatedActivity.id),
        updatedActivity
      );
      saveActivities(state.list);
    },

    deleteActivity: (state, action: PayloadAction<string>) => {
      state.list = state.list.filter(
        (activity) => activity.id !== action.payload
      );
      saveActivities(state.list);
    },

    deleteTimeBlock: (
      state,
      action: PayloadAction<{ activityId: string; timeBlockIndex: number }>
    ) => {
      const { activityId, timeBlockIndex } = action.payload;
      const activityIndex = state.list.findIndex(
        (activity) => activity.id === activityId
      );

      if (activityIndex !== -1) {
        state.list[activityIndex].timeBlocks.splice(timeBlockIndex, 1);

        if (state.list[activityIndex].timeBlocks.length === 0) {
          state.list.splice(activityIndex, 1);
        } else {
          state.list[activityIndex].timeBlocks = consolidateTimeBlocks(
            state.list[activityIndex].timeBlocks
          );
        }

        saveActivities(state.list);
      }
    },
  },
});

export const {
  addActivity,
  updateActivity,
  deleteActivity,
  deleteTimeBlock,
  resetActivities,
} = activitiesSlice.actions;
export default activitiesSlice.reducer;
