import { memo, FC, useCallback, useMemo, useState, useEffect } from "react";
import clsx from "clsx";
import BlockTooltip from "components/BlockTooltip";
import { useSelector } from "react-redux";
import { TimeFormat } from "store/blockSize";
import { ActivityWithType } from "store/selectors/selectActivitiesWithType";
import selectBlockSizeMinutes from "store/selectors/selectBlockSizeMinutes";
import selectTimeFormat from "store/selectors/selectTimeFormat";
import { getContrastingTextColor, getLighterColor } from "utils/colors";
import { convertBlockIndexToTime } from "utils/time";
import styles from "./Block.module.css";

interface BlockProps {
  index: number;
  activity?: ActivityWithType;
  isSelected: boolean;
  isPreviewHover: boolean;
  color: string | null;
  onClick: (shiftKey: boolean) => void;
  onHover: (shiftKey: boolean) => void;
  blockIndices: number[];
  isHoveredLegend: boolean;
}

const formatBlockTime = (time: string, timeFormat: TimeFormat) => {
  const date = new Date(time);
  if (timeFormat === "24h") {
    return { hour: date.getHours().toString().padStart(2, "0"), amPm: "" };
  } else {
    const hours = date.getHours();
    const amPm = hours >= 12 ? "pm" : "am";
    const hour = hours % 12 || 12;
    return { hour: hour.toString(), amPm };
  }
};

const Block: FC<BlockProps> = ({
  index,
  activity,
  isSelected,
  isPreviewHover,
  color,
  onClick,
  onHover,
  blockIndices,
  isHoveredLegend,
}) => {
  const timeFormat = useSelector(selectTimeFormat);
  const blockSizeMinutes = useSelector(selectBlockSizeMinutes);
  const [blockState, setBlockState] = useState<"future" | "current" | "past">(
    "future"
  );
  const [initialFillPercentage, setInitialFillPercentage] = useState(0);
  const [animationDuration, setAnimationDuration] = useState("0s");

  useEffect(() => {
    if (isSelected || isHoveredLegend) {
      let timing: number;
      document.getAnimations().forEach((anim) => {
        if (!timing) {
          timing = anim.currentTime ?? 0;
        }
        anim.currentTime = timing;
      });
    }
  }, [isSelected, isHoveredLegend]);

  useEffect(() => {
    const calculateBlockState = () => {
      const now = new Date();
      const currentMinute = now.getHours() * 60 + now.getMinutes();
      const currentSecond = now.getSeconds();
      const blockStartMinute = index * blockSizeMinutes;
      const blockEndMinute = blockStartMinute + blockSizeMinutes;

      if (currentMinute >= blockEndMinute) {
        setBlockState("past");
        setInitialFillPercentage(100);
        setAnimationDuration("0s");
      } else if (currentMinute >= blockStartMinute) {
        setBlockState("current");
        const elapsedTime =
          (currentMinute - blockStartMinute) * 60 + currentSecond;
        const elapsedPercentage = (elapsedTime / (blockSizeMinutes * 60)) * 100;
        setInitialFillPercentage(elapsedPercentage);
        const remainingTime = blockSizeMinutes * 60 - elapsedTime;
        setAnimationDuration(`${remainingTime}s`);

        // Set timeout to change state to 'past' when the block ends
        const timeUntilEnd =
          (blockEndMinute - currentMinute) * 60 * 1000 - currentSecond * 1000;
        setTimeout(() => setBlockState("past"), timeUntilEnd);
      } else {
        setBlockState("future");
        setInitialFillPercentage(0);
        setAnimationDuration("0s");

        // Set timeout to change state to 'current' when the block starts
        const timeUntilStart =
          (blockStartMinute - currentMinute) * 60 * 1000 - currentSecond * 1000;
        setTimeout(() => calculateBlockState(), timeUntilStart);
      }
    };

    calculateBlockState();
  }, [index, blockSizeMinutes]);

  const { blockBackgroundColor, blockPastColor } = useMemo(() => {
    const baseColor = activity?.color ?? "lightgrey";
    const lightBaseColor = getLighterColor(baseColor);

    if (isSelected && color) {
      return {
        blockBackgroundColor: color,
        blockPastColor: getLighterColor(color),
      };
    }

    if (isPreviewHover && color) {
      return {
        blockBackgroundColor: getLighterColor(color),
        blockPastColor: color,
      };
    }

    if (activity) {
      return {
        blockBackgroundColor: baseColor,
        blockPastColor: lightBaseColor,
      };
    }

    return {
      blockBackgroundColor: "white",
      blockPastColor: getLighterColor("lightgrey"),
    };
  }, [activity, isSelected, isPreviewHover, color]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      onClick(event.shiftKey);
    },
    [onClick]
  );

  const handleHover = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      onHover(event.shiftKey);
    },
    [onHover]
  );

  const hourText = useMemo(() => {
    if (blockSizeMinutes === 15 && index % 4 === 0) {
      const time = convertBlockIndexToTime(index, blockSizeMinutes);
      return formatBlockTime(time, timeFormat);
    } else if (blockSizeMinutes === 30 && index % 2 === 0) {
      const time = convertBlockIndexToTime(index, blockSizeMinutes);
      return formatBlockTime(time, timeFormat);
    } else if (blockSizeMinutes === 60) {
      const time = convertBlockIndexToTime(index, blockSizeMinutes);
      return formatBlockTime(time, timeFormat);
    }
    return null;
  }, [blockSizeMinutes, index, timeFormat]);

  const textColor = useMemo(() => {
    if (blockState === "current") {
      return getContrastingTextColor(blockBackgroundColor);
    }

    return getContrastingTextColor(blockPastColor);
  }, [blockBackgroundColor, blockPastColor, blockState]);

  const style = useMemo(
    () => ({
      "--block-text-color": textColor,
      "--block-background-color": blockBackgroundColor,
      "--block-past-color": blockPastColor,
      "--block-initial-fill": `${initialFillPercentage}%`,
      "--block-animation-duration": animationDuration,
      animationDelay: `${blockIndices.indexOf(index) * 0.02}s`,
    }),
    [
      blockBackgroundColor,
      blockPastColor,
      textColor,
      initialFillPercentage,
      animationDuration,
      blockIndices,
      index,
    ]
  );

  return (
    <BlockTooltip activity={activity} index={index}>
      <div
        data-index={index}
        className={clsx(
          styles.block,
          blockState === "current" && styles.current,
          blockState === "past" && styles.past,
          isPreviewHover && styles.preview,
          blockIndices.includes(index) && styles.activityBlock,
          (isSelected || isHoveredLegend) && styles.selected
        )}
        style={style}
        onClick={handleClick}
        onMouseOver={handleHover}
      >
        {hourText !== null && (
          <p className={styles.hourText}>
            {hourText.hour}
            <span className={styles.amPm}>{hourText.amPm}</span>
          </p>
        )}
      </div>
    </BlockTooltip>
  );
};

export default memo(Block);
