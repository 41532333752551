import {
  parseISO,
  startOfToday,
  startOfTomorrow,
  isWithinInterval,
} from "date-fns";
import { TimeFormat } from "store/blockSize";
import { Activity, ActivityType, BlockSize } from "types";

const BLOCK_SIZE_KEY = "blockSize";
const ACTIVITIES_KEY = "activities";
const ACTIVITY_TYPES_KEY = "activityTypes";

export const loadBlockSize = (): BlockSize | undefined => {
  const storedBlockSize = localStorage.getItem(BLOCK_SIZE_KEY);
  return storedBlockSize ? JSON.parse(storedBlockSize) : undefined;
};

export const saveBlockSize = (blockSize: BlockSize): void => {
  localStorage.setItem(BLOCK_SIZE_KEY, JSON.stringify(blockSize));
};

export const loadTimeFormat = (): TimeFormat | null => {
  const storedTimeFormat = localStorage.getItem("timeFormat");
  return storedTimeFormat as TimeFormat | null;
};

export const saveTimeFormat = (timeFormat: TimeFormat) => {
  localStorage.setItem("timeFormat", timeFormat);
};

export const loadActivities = (): Activity[] => {
  const storedActivities = localStorage.getItem(ACTIVITIES_KEY);
  return storedActivities ? JSON.parse(storedActivities) : [];
};

export const saveActivities = (activities: Activity[]): void => {
  localStorage.setItem(ACTIVITIES_KEY, JSON.stringify(activities));
};

export const loadActivityTypes = (): ActivityType[] => {
  const storedTypes = localStorage.getItem(ACTIVITY_TYPES_KEY);
  return storedTypes ? JSON.parse(storedTypes) : [];
};

export const saveActivityTypes = (types: ActivityType[]): void => {
  localStorage.setItem(ACTIVITY_TYPES_KEY, JSON.stringify(types));
};

export const filterActivitiesForToday = (
  activities: Activity[]
): Activity[] => {
  const todayStart = startOfToday();
  const todayEnd = startOfTomorrow();

  return activities.filter((activity) =>
    activity.timeBlocks.some((block) => {
      return (
        isWithinInterval(parseISO(block.start), {
          start: todayStart,
          end: todayEnd,
        }) &&
        isWithinInterval(parseISO(block.end), {
          start: todayStart,
          end: todayEnd,
        })
      );
    })
  );
};
