import { FC, useMemo } from "react";
import * as Tooltip from "@radix-ui/react-tooltip";
import { useSelector } from "react-redux";
import { ActivityWithType } from "store/selectors/selectActivitiesWithType";
import selectBlockSizeMinutes from "store/selectors/selectBlockSizeMinutes";
import selectTimeFormat from "store/selectors/selectTimeFormat";
import { convertBlockIndexToTime, formatTime } from "utils/time";
import styles from "./BlockTooltip.module.css";

interface BlockTooltipProps {
  children: React.ReactNode;
  activity?: ActivityWithType;
  index: number;
}

const BlockTooltip: FC<BlockTooltipProps> = ({ children, activity, index }) => {
  const timeFormat = useSelector(selectTimeFormat);
  const blockSizeMinutes = useSelector(selectBlockSizeMinutes);
  const startTime = useMemo(
    () => convertBlockIndexToTime(index, blockSizeMinutes),
    [index, blockSizeMinutes]
  );

  const endTime = useMemo(
    () => convertBlockIndexToTime(index + 1, blockSizeMinutes),
    [index, blockSizeMinutes]
  );

  return (
    <Tooltip.Provider delayDuration={300}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content className={styles.tooltipContent} sideOffset={5}>
            {activity?.color ? (
              <div
                className={styles.colorDot}
                style={{ backgroundColor: activity.color }}
              />
            ) : null}
            {activity?.title ? <p>{activity.title}</p> : null}
            <p>
              {`${formatTime(startTime, timeFormat)} - ${formatTime(
                endTime,
                timeFormat
              )}`}
            </p>
            <Tooltip.Arrow className={styles.tooltipArrow} />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export default BlockTooltip;
