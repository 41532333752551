import { FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setBlockSizeMinutes } from "store/blockSize";
import selectBlockSizeMinutes from "store/selectors/selectBlockSizeMinutes";
import { BlockSize } from "types";

const BlockSizeSelect: FC = () => {
  const dispatch = useDispatch();
  const value = useSelector(selectBlockSizeMinutes);

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(
      setBlockSizeMinutes(parseInt(event.target.value, 10) as BlockSize)
    );
  };

  return (
    <select id="block-size-select" value={value} onChange={onChange}>
      <option value="15">15</option>
      <option value="30">30</option>
      <option value="60">60</option>
    </select>
  );
};

export default BlockSizeSelect;
