import Color from "color";

const availableColors = [
  "#e78284", // Red
  "#a6d189", // Green
  "#8caaee", // Blue
  "#ef9f76", // Orange
  "#ca9ee6", // Purple
  "#e5c890", // Yellow
  "#f4b8e4", // Pink
  "#81c8be", // Teal
  "#babbf1", // Lavender
  "#f2cdcd", // Flamingo (light pink)
  "#b7bdf8", // Light blue
  "#eed49f", // Light yellow
];

export function getRandomColor(excludedColors: string[] = []): string {
  const colors = availableColors.filter(
    (color) => !excludedColors.includes(color)
  );

  if (colors.length > 0) {
    const index = Math.floor(Math.random() * colors.length);
    return colors.splice(index, 1)[0];
  }

  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function getLighterColor(color: string): string {
  return new Color(color).lighten(0.1).hex();
}

export function getContrastingTextColor(color: string): string {
  return new Color(color).isDark() ? "#FFFFFF" : "#000000";
}
