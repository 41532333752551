import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { ActivityType } from "types";
import { saveActivityTypes } from "utils/localStorage";
import { v4 as uuidv4 } from "uuid";

interface ActivityTypeState {
  list: ActivityType[];
}

const initialState: ActivityTypeState = {
  list: [],
};

export const addActivityType = createAsyncThunk<
  ActivityType,
  Omit<ActivityType, "id" | "archived">
>("activityType/addActivityType", (newActivityType) => {
  return {
    ...newActivityType,
    id: uuidv4(),
    archived: false,
  };
});

const activityTypeSlice = createSlice({
  name: "activityType",
  initialState,
  reducers: {
    resetActivityTypes: () => {
      saveActivityTypes([]);

      return initialState;
    },
    updateActivityType: (state, action: PayloadAction<ActivityType>) => {
      const index = state.list.findIndex(
        (type) => type.id === action.payload.id
      );

      if (index !== -1) {
        state.list[index] = action.payload;
        saveActivityTypes(state.list);
      }
    },
    removeActivityType: (state, action: PayloadAction<string>) => {
      state.list = state.list.filter((type) => type.id !== action.payload);
      saveActivityTypes(state.list);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(addActivityType.fulfilled, (state, action) => {
      state.list.push(action.payload);
      saveActivityTypes(state.list);
    });
  },
});

export const { updateActivityType, removeActivityType, resetActivityTypes } =
  activityTypeSlice.actions;
export default activityTypeSlice.reducer;
