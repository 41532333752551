import { useMemo } from "react";
import { useSelector } from "react-redux";
import selectActivityColors from "store/selectors/selectActivityColors";
import { getRandomColor } from "utils/colors";

const useActivityColor = () => {
  const colors = useSelector(selectActivityColors);

  return useMemo(() => getRandomColor(colors), [colors]);
};

export default useActivityColor;
