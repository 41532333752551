import { FC, useEffect, useState } from "react";
import LogoClock from "components/LogoClock";
import BlockSizeSelect from "./BlockSizeSelect";

const useDayName = () => {
  const [dayName, setDayName] = useState("");

  useEffect(() => {
    const updateDayName = () => {
      const currentDate = new Date();
      const dayNameFormatter = new Intl.DateTimeFormat("en-US", {
        weekday: "long",
      });
      setDayName(dayNameFormatter.format(currentDate));
    };

    updateDayName();

    const handleDateChange = () => {
      updateDayName();
    };

    window.addEventListener("focus", handleDateChange);
    window.addEventListener("visibilitychange", handleDateChange);

    return () => {
      window.removeEventListener("focus", handleDateChange);
      window.removeEventListener("visibilitychange", handleDateChange);
    };
  }, []);

  return dayName;
};

const Header: FC = () => {
  const dayName = useDayName();

  return (
    <header>
      <LogoClock />
      <h2>
        your {dayName} in
        <BlockSizeSelect />
        minute blocks
      </h2>
    </header>
  );
};

export default Header;
