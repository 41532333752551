import { FC, useMemo, useRef, useState } from "react";
import {
  GearIcon,
  ArchiveIcon,
  ExclamationTriangleIcon,
} from "@radix-ui/react-icons";
import clsx from "clsx";
import EditableTitle from "components/EditableTitle";
import { useDispatch, useSelector } from "react-redux";
import { resetStore } from "store";
import { resetActivities } from "store/activities";
import { updateActivityType } from "store/activityType";
import { setTimeFormat } from "store/blockSize";
import selectActivityTypes from "store/selectors/selectActivityTypes";
import selectTimeFormat from "store/selectors/selectTimeFormat";
import { ActivityType } from "types";
import Modal from "./Modal";
import styles from "./SettingsModal.module.css";

const SettingsModal: FC = () => {
  const dispatch = useDispatch();
  const activityTypes = useSelector(selectActivityTypes);
  const timeFormat = useSelector(selectTimeFormat);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const colorInputRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});

  const handleNameChange = (type: ActivityType, newTitle: string) => {
    if (newTitle !== type.title && newTitle.trim() !== "") {
      dispatch(updateActivityType({ ...type, title: newTitle.trim() }));
    }
  };

  const handleColorChange = (type: ActivityType, newColor: string) => {
    dispatch(updateActivityType({ ...type, color: newColor }));
  };

  const handleArchiveChange = (type: ActivityType) => {
    dispatch(updateActivityType({ ...type, archived: !type.archived }));
  };

  const handleResetActivities = () => {
    dispatch(resetActivities());
  };

  const handleResetAllData = () => {
    resetStore(dispatch);
  };

  const handleTimeFormatChange = (newFormat: "12h" | "24h") => {
    dispatch(setTimeFormat(newFormat));
  };

  const openColorPicker = (typeId: string) => {
    colorInputRefs.current[typeId]?.click();
  };

  const sortedActivityTypes = useMemo(() => {
    return [...activityTypes]
      .sort((a, b) => a.title.localeCompare(b.title))
      .sort((a, b) => {
        if (a.archived && !b.archived) return 1;
        if (!a.archived && b.archived) return -1;
        return 0;
      });
  }, [activityTypes]);

  return (
    <>
      <button onClick={() => setIsOpen(true)} className={styles.settingsButton}>
        <GearIcon className={styles.icon} />
        Settings
      </button>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} title="Settings">
        <section className={styles.section}>
          <h3 className={styles.sectionTitle}>Time Format</h3>
          <div className={styles.timeFormatToggle}>
            <button
              className={clsx(
                styles.timeFormatOption,
                timeFormat === "12h" && styles.selected
              )}
              onClick={() => handleTimeFormatChange("12h")}
            >
              12-hour
            </button>
            <button
              className={clsx(
                styles.timeFormatOption,
                timeFormat === "24h" && styles.selected
              )}
              onClick={() => handleTimeFormatChange("24h")}
            >
              24-hour
            </button>
          </div>
        </section>
        <section className={styles.section}>
          <h3 className={styles.sectionTitle}>Manage Activity Types</h3>
          {activityTypes.length > 0 ? (
            <ul className={styles.activityTypeList}>
              {sortedActivityTypes.map((type) => (
                <li
                  key={type.id}
                  className={clsx(
                    styles.activityTypeRow,
                    type.archived && styles.archivedType
                  )}
                >
                  <button
                    className={styles.colorButton}
                    style={{ backgroundColor: type.color }}
                    onClick={() => openColorPicker(type.id)}
                    aria-label="Choose color"
                    disabled={type.archived}
                  />
                  <input
                    ref={(el) => (colorInputRefs.current[type.id] = el)}
                    type="color"
                    value={type.color}
                    onChange={(e) => handleColorChange(type, e.target.value)}
                    className={styles.hiddenColorInput}
                    disabled={type.archived}
                  />
                  <EditableTitle
                    initialTitle={type.title}
                    onSave={(newTitle) => handleNameChange(type, newTitle)}
                    disabled={type.archived}
                  />
                  <button
                    onClick={() => handleArchiveChange(type)}
                    className={styles.dangerButton}
                    aria-label={type.archived ? "Unarchive" : "Archive"}
                  >
                    {type.archived ? "Unarchive" : "Archive"}
                    <ArchiveIcon style={{ marginLeft: "0.5em" }} />
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p className={styles.helperText}>
              No activity types. Time to start filling in your dots!
            </p>
          )}
        </section>

        <section className={styles.section}>
          <h3 className={styles.sectionTitle}>Reset Options</h3>
          <div className={styles.resetOptions}>
            <div>
              <button
                onClick={handleResetActivities}
                className={styles.dangerButton}
              >
                Reset Activities for Today
              </button>
              <p className={styles.helperText}>
                <ExclamationTriangleIcon style={{ marginRight: "0.5em" }} />
                This will clear all activities for the current day.
              </p>
            </div>
            <div>
              <button
                onClick={handleResetAllData}
                className={styles.dangerButton}
              >
                Reset All Data
              </button>
              <p className={styles.helperText}>
                <ExclamationTriangleIcon style={{ marginRight: "0.5em" }} />
                This will delete all your data and cannot be undone.
              </p>
            </div>
          </div>
        </section>
      </Modal>
    </>
  );
};

export default SettingsModal;
