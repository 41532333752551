import { createContext, FC, ReactNode, useContext, useState } from "react";

interface SelectedBlocksContextValue {
  selectedBlocks: number[];
  setSelectedBlocks: (blocks: number[]) => void;
  activityColor: string | null;
  setActivityColor: (color: string | null) => void;
}

const SelectedBlocksContext = createContext<SelectedBlocksContextValue>({
  selectedBlocks: [],
  setSelectedBlocks: () => {},
  activityColor: null,
  setActivityColor: () => {},
});

export const useSelectedBlocksContext = () => useContext(SelectedBlocksContext);

interface SelectedBlocksProviderProps {
  children: ReactNode;
}

const SelectedBlocksProvider: FC<SelectedBlocksProviderProps> = ({
  children,
}) => {
  const [selectedBlocks, setSelectedBlocks] = useState<number[]>([]);
  const [activityColor, setActivityColor] = useState<string | null>(null);

  const contextValue: SelectedBlocksContextValue = {
    selectedBlocks,
    setSelectedBlocks,
    activityColor,
    setActivityColor,
  };

  return (
    <SelectedBlocksContext.Provider value={contextValue}>
      {children}
    </SelectedBlocksContext.Provider>
  );
};

export default SelectedBlocksProvider;
