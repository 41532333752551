import { createSelector } from "@reduxjs/toolkit";
import { startOfDay, addDays, isWithinInterval, parseISO } from "date-fns";
import selectActivitiesWithType from "store/selectors/selectActivitiesWithType";

function createActivitiesPerDateSelector(date: string) {
  const start = startOfDay(date);
  const end = addDays(start, 1);

  return createSelector(selectActivitiesWithType, (activities) => {
    return activities.filter((activity) => {
      return activity.timeBlocks.some((block) => {
        return (
          isWithinInterval(parseISO(block.start), {
            start,
            end,
          }) &&
          isWithinInterval(parseISO(block.end), {
            start,
            end,
          })
        );
      });
    });
  });
}

export default createActivitiesPerDateSelector;
