import { useState, FC, useEffect, useCallback, KeyboardEvent } from "react";
import * as Popover from "@radix-ui/react-popover";
import clsx from "clsx";
import { useSelectedBlocksContext } from "components/SelectedBlocksContext";
import { useCombobox } from "downshift";
import { useSelector } from "react-redux";
import selectInputActivityTypes from "store/selectors/selectInputActivityTypes";
import { ActivityType } from "types";
import styles from "./ActivityInput.module.css";

interface ActivityInputProps {
  value: string;
  onChange: (value: string) => void;
  onSelect: (activityType: ActivityType | null) => void;
}

const ActivityInput: FC<ActivityInputProps> = ({
  value,
  onChange,
  onSelect,
}) => {
  const { selectedBlocks, activityColor } = useSelectedBlocksContext();
  const activityTypes = useSelector(selectInputActivityTypes);
  const [inputItems, setInputItems] = useState<ActivityType[]>(activityTypes);
  const [isCreating, setIsCreating] = useState(false);

  const handleKeyUp = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Escape") {
      event.stopPropagation();
    }
  }, []);

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getItemProps,
    highlightedIndex,
    setHighlightedIndex,
  } = useCombobox({
    items: inputItems,
    inputValue: value,
    onInputValueChange: ({ inputValue }) => {
      const matches = activityTypes.filter((activityType) =>
        activityType.title
          .toLowerCase()
          .includes(inputValue?.toLowerCase() || "")
      );

      if (matches.length > 0) {
        setInputItems(matches);
        setIsCreating(false);
        setHighlightedIndex(0);
      } else if (inputValue && activityColor) {
        setIsCreating(true);
        setInputItems([
          {
            id: "new",
            title: inputValue,
            color: activityColor,
            archived: false,
          },
        ]);
        setHighlightedIndex(0);
      }

      onChange(inputValue || "");
    },
    onSelectedItemChange: ({ selectedItem }) => {
      onSelect(selectedItem);
      setIsCreating(false);
      setInputItems(activityTypes);
    },
    itemToString(item) {
      return item ? item.title : "";
    },
    stateReducer: (state, actionAndChanges) => {
      const { type, changes } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.FunctionSelectItem:
          return {
            ...changes,
            inputValue: "",
            isOpen: false,
          };
        default:
          return changes;
      }
    },
  });

  useEffect(() => setInputItems(activityTypes), [activityTypes]);

  return (
    <Popover.Root open={isOpen}>
      <Popover.Anchor className={styles.anchor}>
        <input
          {...getInputProps({
            onKeyUp: handleKeyUp,
          })}
          placeholder="Enter activity name"
          disabled={!selectedBlocks.length}
        />
      </Popover.Anchor>
      <div {...getMenuProps()}>
        <Popover.Content
          className={clsx(
            styles.popoverContent,
            isOpen ? styles.popoverEntering : styles.popoverExiting,
            inputItems.length > 0 && styles.popoverContentOpen
          )}
          onOpenAutoFocus={(e) => e.preventDefault()}
        >
          {inputItems.length > 0 ? (
            <ul className={styles.dropdownList}>
              {inputItems.map((item, index) => (
                <li
                  key={item.id}
                  {...getItemProps({ item, index })}
                  className={clsx(
                    styles.dropdownItem,
                    highlightedIndex === index && styles.dropdownItemHighlighted
                  )}
                >
                  {isCreating && "Create "}
                  {item.title}
                </li>
              ))}
            </ul>
          ) : null}
        </Popover.Content>
      </div>
    </Popover.Root>
  );
};

export default ActivityInput;
