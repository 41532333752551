import { createSelector } from "@reduxjs/toolkit";
import { Activity } from "types";
import selectActivities from "./selectActivities";
import selectActivityTypes from "./selectActivityTypes";

export interface ActivityWithType extends Activity {
  title: string;
  color: string;
}

const selectActivitiesWithType = createSelector(
  [selectActivities, selectActivityTypes],
  (activities, activityTypes): ActivityWithType[] => {
    return activities.map((activity) => {
      const activityType = activityTypes.find(
        (type) => type.id === activity.typeId
      );
      return {
        ...activity,
        title: activityType?.title || "",
        color: activityType?.color || "",
      };
    });
  }
);

export default selectActivitiesWithType;
