import {
  useState,
  useEffect,
  FC,
  ReactNode,
  ButtonHTMLAttributes,
} from "react";
import styles from "./ShinyFoilButton.module.css";

interface ShinyFoilButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
}

const ShinyFoilButton: FC<ShinyFoilButtonProps> = ({ children, ...props }) => {
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      const btn = e.currentTarget as HTMLButtonElement;
      const rect = btn.getBoundingClientRect();
      setPosition({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      });
    };

    const button = document.querySelector(`.${styles.shinyButton}`);
    if (button) {
      button.addEventListener("mousemove", handleMouseMove as EventListener);
    }

    return () => {
      if (button) {
        button.removeEventListener(
          "mousemove",
          handleMouseMove as EventListener
        );
      }
    };
  }, []);

  return (
    <button className={styles.shinyButton} {...props}>
      <span className={styles.buttonText}>{children}</span>
      <div
        className={styles.shinyEffect}
        style={{
          background: `radial-gradient(circle 80px at ${position.x}px ${position.y}px, rgba(255,255,255,0.2), transparent)`,
        }}
      />
    </button>
  );
};

export default ShinyFoilButton;
