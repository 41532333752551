import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BlockSize } from "types";
import { saveBlockSize, saveTimeFormat } from "utils/localStorage";
import { getDefaultTimeFormat } from "utils/time";

export type TimeFormat = "12h" | "24h";

interface BlockSizeState {
  minutes: BlockSize;
  timeFormat: TimeFormat;
}

const initialState: BlockSizeState = {
  minutes: 30,
  timeFormat: getDefaultTimeFormat(),
};

const blockSizeSlice = createSlice({
  name: "blockSize",
  initialState,
  reducers: {
    resetBlockSize: () => {
      saveBlockSize(30);
      saveTimeFormat(getDefaultTimeFormat());
      return initialState;
    },
    setBlockSizeMinutes: (state, action: PayloadAction<BlockSize>) => {
      state.minutes = action.payload;
      saveBlockSize(action.payload);
    },
    setTimeFormat: (state, action: PayloadAction<TimeFormat>) => {
      state.timeFormat = action.payload;
      saveTimeFormat(action.payload);
    },
  },
});

export const { setBlockSizeMinutes, resetBlockSize, setTimeFormat } =
  blockSizeSlice.actions;
export default blockSizeSlice.reducer;
