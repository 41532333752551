import React, { useState, useEffect, useRef, useCallback } from "react";
import FontFaceObserver from "fontfaceobserver";
import styles from "./LogoClock.module.css";

function drawText(
  ctx: CanvasRenderingContext2D,
  text: string,
  x: number,
  y: number,
  fontSize: number,
  color: string
): void {
  ctx.font = `bold ${fontSize}px OhnoSoftieDemo-Black, sans-serif`;
  ctx.fillStyle = color;
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";
  ctx.fillText(text, x, y);
}

const LogoClock: React.FC = () => {
  const [time, setTime] = useState(new Date());
  const [fontLoaded, setFontLoaded] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const font = new FontFaceObserver("OhnoSoftieDemo-Black");
    font
      .load(null, 5000)
      .then(() => {
        console.log("Custom font has loaded.");
        setFontLoaded(true);
      })
      .catch((e) => {
        console.log("Custom font failed to load:", e);
        setFontLoaded(true); // Set to true anyway to allow rendering
      });
  }, []);

  const updateFavicon = useCallback(() => {
    if (!fontLoaded) return;

    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.clearRect(0, 0, 64, 64);

        drawText(ctx, "o", 32, 32, 115, "rgb(231, 100, 116)");

        const minutes = time.getMinutes();
        const hours = time.getHours() % 12;
        const minuteAngle = minutes * 6;
        const hourAngle = hours * 30 + minutes * 0.5;

        ctx.save();
        ctx.translate(32, 32);
        ctx.rotate((hourAngle * Math.PI) / 180);
        drawText(ctx, "I", 2, -6, 32, "black");
        ctx.restore();

        ctx.save();
        ctx.translate(32, 32);
        ctx.rotate((minuteAngle * Math.PI) / 180);
        drawText(ctx, "I", 0, -6, 45, "black");
        ctx.restore();

        // Update favicon
        const faviconUrl = canvas.toDataURL("image/png");
        let linkElement = document.querySelector(
          "link[rel*='icon']"
        ) as HTMLLinkElement | null;

        if (!linkElement) {
          linkElement = document.createElement("link");
          document.getElementsByTagName("head")[0].appendChild(linkElement);
        }

        linkElement.href = faviconUrl;
      }
    }
  }, [time, fontLoaded]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (fontLoaded) {
      updateFavicon();
    }
  }, [updateFavicon, fontLoaded]);

  const minutes = time.getMinutes();
  const hours = time.getHours() % 12;
  const minuteAngle = minutes * 6;
  const hourAngle = hours * 30 + minutes * 0.5;

  const formattedTime = time.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  if (!fontLoaded) {
    return null; // Or return a loading indicator
  }

  return (
    <>
      <canvas
        ref={canvasRef}
        width="64"
        height="64"
        style={{ display: "none" }}
      />
      <div
        className={styles.container}
        role="img"
        aria-label="Analog clock"
        tabIndex={0}
      >
        <div className={styles.clockFace} aria-hidden="true">
          o
        </div>
        <div
          className={styles.hourHand}
          style={{ transform: `rotate(${hourAngle}deg)` }}
          aria-hidden="true"
        >
          I
        </div>
        <div
          className={styles.minuteHand}
          style={{ transform: `rotate(${minuteAngle}deg)` }}
          aria-hidden="true"
        >
          I
        </div>
        <div style={{ visibility: "hidden" }}>
          Current time: {formattedTime}
        </div>
      </div>
    </>
  );
};

export default LogoClock;
