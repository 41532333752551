import {
  parseISO,
  differenceInMinutes,
  startOfDay,
  isBefore,
  isAfter,
  addMinutes,
  format,
} from "date-fns";
import { enUS, enGB } from "date-fns/locale";
import { TimeFormat } from "store/blockSize";
import { ActivityWithType } from "store/selectors/selectActivitiesWithType";
import { Activity, BlockSize, TimeBlock } from "types";

export const formatTime = (date: string, timeFormat: TimeFormat): string => {
  const parsedDate = parseISO(date);
  return timeFormat === "12h"
    ? format(parsedDate, "h:mm a", { locale: enUS })
    : format(parsedDate, "HH:mm", { locale: enGB });
};

export const getDefaultTimeFormat = (): TimeFormat => {
  const userLocale = navigator.language;
  const uses24HourFormat = new Intl.DateTimeFormat(userLocale, {
    hour: "numeric",
  })
    .format(new Date(2000, 0, 1, 13))
    .includes("13");

  return uses24HourFormat ? "24h" : "12h";
};

export const formatDuration = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  if (!hours) {
    return `${remainingMinutes}m`;
  } else if (!remainingMinutes) {
    return `${hours}h`;
  }
  return `${hours}h ${remainingMinutes}m`;
};

export const calculateTimeBlockDuration = (timeBlock: TimeBlock): number => {
  return differenceInMinutes(
    parseISO(timeBlock.end),
    parseISO(timeBlock.start)
  );
};

export const calculateTotalTimeUsed = (activities: Activity[]): number => {
  return activities.reduce((total, activity) => {
    const activityDuration = activity.timeBlocks.reduce(
      (blockTotal, timeBlock) =>
        blockTotal + calculateTimeBlockDuration(timeBlock),
      0
    );
    return total + activityDuration;
  }, 0);
};

export const calculateTimeLeftInDay = (activities: Activity[]): number => {
  const totalMinutesInDay = 24 * 60; // 24 hours * 60 minutes
  const totalTimeUsed = calculateTotalTimeUsed(activities);

  return totalMinutesInDay - totalTimeUsed;
};

export const getFormattedTimeLeft = (
  activities: Activity[],
  timeFormat: TimeFormat
): string => {
  const timeLeftInMinutes = calculateTimeLeftInDay(activities);
  const timeLeftDate = addMinutes(startOfDay(new Date()), timeLeftInMinutes);
  return formatTime(timeLeftDate.toISOString(), timeFormat);
};

export const convertBlockIndexToTime = (
  blockIndex: number,
  blockSizeMinutes: BlockSize
): string => {
  const startOfDayDate = startOfDay(new Date());
  return addMinutes(
    startOfDayDate,
    blockIndex * blockSizeMinutes
  ).toISOString();
};

export const convertTimeToBlockIndex = (
  time: string,
  blockSizeMinutes: BlockSize
): number => {
  const timeDate = parseISO(time);
  const dayStart = startOfDay(timeDate);
  return Math.ceil(differenceInMinutes(timeDate, dayStart) / blockSizeMinutes);
};

export function mapActivitiesToBlocks(
  activities: ActivityWithType[],
  blockSizeMinutes: number
): Map<number, ActivityWithType> {
  const blockMap = new Map<number, ActivityWithType>();

  activities.forEach((activity) => {
    activity.timeBlocks.forEach((timeBlock) => {
      const startTime = parseISO(timeBlock.start);
      const endTime = parseISO(timeBlock.end);
      const dayStart = startOfDay(startTime);

      let currentBlockStart = dayStart;
      let blockIndex = 0;

      while (isBefore(currentBlockStart, endTime)) {
        const currentBlockEnd = addMinutes(currentBlockStart, blockSizeMinutes);

        if (
          isAfter(currentBlockEnd, startTime) &&
          isBefore(currentBlockStart, endTime)
        ) {
          blockMap.set(blockIndex, activity);
        }

        currentBlockStart = currentBlockEnd;
        blockIndex++;
      }
    });
  });

  return blockMap;
}

export const getActivityBlocks = (
  activity: Activity,
  blockSizeMinutes: BlockSize
): number[] => {
  const blocks = new Set<number>();

  activity.timeBlocks.forEach((timeBlock) => {
    const startIndex = convertTimeToBlockIndex(
      timeBlock.start,
      blockSizeMinutes
    );
    const endIndex = convertTimeToBlockIndex(timeBlock.end, blockSizeMinutes);

    for (let i = startIndex; i <= endIndex; i++) {
      blocks.add(i);
    }
  });

  return Array.from(blocks).sort((a, b) => a - b);
};
