import { FC } from "react";
import ActivityForm from "components/ActivityForm";
import AlphaIndicator from "components/AlphaIndicator";
import Container from "components/Container";
import Header from "components/Header";
import Legend from "components/Legend";
import SelectedBlocksProvider from "components/SelectedBlocksContext";
import SettingsModal from "components/SettingsModal";
import ShinyFoilButton from "components/ShinyFoilButton";
import "styles/global.css";

const App: FC = () => {
  return (
    <SelectedBlocksProvider>
      <div className="app">
        <nav className="nav">
          <AlphaIndicator />
          <SettingsModal />
          <ShinyFoilButton
            data-tally-open="nrEQ0L"
            data-tally-emoji-text="👋"
            data-tally-emoji-animation="wave"
          >
            Give Feedback
          </ShinyFoilButton>
        </nav>
        <Header />
        <div className="content">
          <Container />
          <div>
            <ActivityForm />
            <Legend />
          </div>
        </div>
      </div>
    </SelectedBlocksProvider>
  );
};

export default App;
