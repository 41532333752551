import { Activity, TimeBlock } from "types";

export function consolidateTimeBlocks(timeBlocks: TimeBlock[]): TimeBlock[] {
  if (timeBlocks.length === 0) return [];

  const sortedBlocks = [...timeBlocks].sort(
    (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()
  );
  const consolidatedBlocks: TimeBlock[] = [{ ...sortedBlocks[0] }];

  for (let i = 1; i < sortedBlocks.length; i++) {
    const currentBlock = sortedBlocks[i];
    const lastConsolidatedBlock =
      consolidatedBlocks[consolidatedBlocks.length - 1];

    if (
      new Date(currentBlock.start).getTime() ===
      new Date(lastConsolidatedBlock.end).getTime()
    ) {
      consolidatedBlocks[consolidatedBlocks.length - 1] = {
        ...lastConsolidatedBlock,
        end: currentBlock.end,
      };
    } else {
      consolidatedBlocks.push({ ...currentBlock });
    }
  }

  return consolidatedBlocks;
}

export function updateActivitiesTimeBlocks(
  activities: Activity[],
  newOrUpdatedActivity: Activity
): Activity[] {
  const updatedActivities = activities.map((activity) => ({
    ...activity,
    timeBlocks: consolidateTimeBlocks(
      adjustOverlappingTimeBlocks(
        activity.timeBlocks,
        newOrUpdatedActivity.timeBlocks
      )
    ),
  }));

  return [...updatedActivities, newOrUpdatedActivity];
}

function adjustOverlappingTimeBlocks(
  existingTimeBlocks: TimeBlock[],
  newTimeBlocks: TimeBlock[]
): TimeBlock[] {
  return existingTimeBlocks.flatMap((existingBlock) => {
    const overlappingNewBlocks = newTimeBlocks.filter((newBlock) =>
      doTimeBlocksOverlap(existingBlock, newBlock)
    );

    if (overlappingNewBlocks.length === 0) {
      return [existingBlock];
    }

    return splitTimeBlock(existingBlock, overlappingNewBlocks);
  });
}

function doTimeBlocksOverlap(block1: TimeBlock, block2: TimeBlock): boolean {
  return (
    new Date(block1.start) < new Date(block2.end) &&
    new Date(block1.end) > new Date(block2.start)
  );
}

function splitTimeBlock(
  block: TimeBlock,
  overlappingBlocks: TimeBlock[]
): TimeBlock[] {
  let result: TimeBlock[] = [block];

  overlappingBlocks.forEach((overlappingBlock) => {
    result = result.flatMap((resultBlock) => {
      if (doTimeBlocksOverlap(resultBlock, overlappingBlock)) {
        const splitBlocks: TimeBlock[] = [];
        if (new Date(resultBlock.start) < new Date(overlappingBlock.start)) {
          splitBlocks.push({
            start: resultBlock.start,
            end: overlappingBlock.start,
          });
        }
        if (new Date(resultBlock.end) > new Date(overlappingBlock.end)) {
          splitBlocks.push({
            start: overlappingBlock.end,
            end: resultBlock.end,
          });
        }
        return splitBlocks;
      }
      return [resultBlock];
    });
  });

  return result;
}
