import { FC } from "react";
import { DownloadIcon } from "@radix-ui/react-icons";
import { format, isSameDay, parseISO } from "date-fns";
import { useSelector } from "react-redux";
import selectTimeFormat from "store/selectors/selectTimeFormat";
import selectTodayActivities from "store/selectors/selectTodayActivities";
import { TimeBlock } from "types";
import { formatTime } from "utils/time";
import styles from "./ExportMarkdownButton.module.css";

interface ScheduleItem extends TimeBlock {
  activityTitle: string;
}

const ExportMarkdownButton: FC = () => {
  const todayActivities = useSelector(selectTodayActivities);
  const timeFormat = useSelector(selectTimeFormat);

  const generateMarkdown = () => {
    const today = new Date();
    const markdownDate = format(today, "yyyy-MM-dd");
    let markdown = `# Schedule for ${markdownDate}\n\n`;

    const allTimeBlocks: ScheduleItem[] = todayActivities.flatMap((activity) =>
      activity.timeBlocks.map((block) => ({
        ...block,
        activityTitle: activity.title,
      }))
    );

    allTimeBlocks.sort(
      (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()
    );

    allTimeBlocks.forEach((block) => {
      const start = parseISO(block.start);

      if (isSameDay(start, today)) {
        const startTime = formatTime(block.start, timeFormat);
        const endTime = formatTime(block.end, timeFormat);
        markdown += `- ${startTime}-${endTime} - **${block.activityTitle}**\n`;
      }
    });

    return markdown;
  };

  const handleExport = () => {
    const markdown = generateMarkdown();
    const blob = new Blob([markdown], { type: "text/markdown" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `activities_${format(new Date(), "yyyy-MM-dd")}.md`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  if (!todayActivities.length) {
    return null;
  }

  return (
    <button onClick={handleExport} className={styles.button}>
      <DownloadIcon />
      Export
    </button>
  );
};

export default ExportMarkdownButton;
