import { createSelector } from "@reduxjs/toolkit";
import selectActivityTypes from "store/selectors/selectActivityTypes";

const selectInputActivityTypes = createSelector(
  selectActivityTypes,
  (activityTypes) => {
    return activityTypes.filter((type) => !type.archived);
  }
);

export default selectInputActivityTypes;
