import React, { useCallback, useState } from "react";
import { PlusCircledIcon } from "@radix-ui/react-icons";
import ActivityInput from "components/ActivityInput";
import { useSelectedBlocksContext } from "components/SelectedBlocksContext";
import useActivityColor from "hooks/useActivityColor";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store";
import { updateActivity } from "store/activities";
import selectBlockSizeMinutes from "store/selectors/selectBlockSizeMinutes";
import selectTodayActivities from "store/selectors/selectTodayActivities";
import { createActivityWithType } from "store/thunks/activityThunks";
import { ActivityType, TimeBlock } from "types";
import { convertBlockIndexToTime } from "utils/time";
import styles from "./ActivityForm.module.css";

const ActivityForm: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const activities = useSelector(selectTodayActivities);
  const blockSizeMinutes = useSelector(selectBlockSizeMinutes);
  const { selectedBlocks, setSelectedBlocks, activityColor, setActivityColor } =
    useSelectedBlocksContext();
  const [title, setTitle] = useState("");

  const color = useActivityColor();

  const convertBlocksToTimeBlocks = useCallback(
    (blocks: number[]): TimeBlock[] => {
      return blocks.map((block) => ({
        start: convertBlockIndexToTime(block, blockSizeMinutes),
        end: convertBlockIndexToTime(block + 1, blockSizeMinutes),
      }));
    },
    [blockSizeMinutes]
  );

  const handleActivitySelect = useCallback(
    (selectedActivityType: ActivityType | null) => {
      if (selectedActivityType) {
        const existingActivity = activities.find(
          (a) => a.typeId === selectedActivityType.id
        );
        if (existingActivity) {
          const updatedTimeBlocks = [
            ...existingActivity.timeBlocks,
            ...convertBlocksToTimeBlocks(selectedBlocks),
          ];
          dispatch(
            updateActivity({
              ...existingActivity,
              timeBlocks: updatedTimeBlocks,
            })
          );
        } else {
          dispatch(
            createActivityWithType({
              activityType: selectedActivityType,
              timeBlocks: convertBlocksToTimeBlocks(selectedBlocks),
            })
          );
        }
        setActivityColor(selectedActivityType.color);
        setSelectedBlocks([]);
        setTitle("");
      }
    },
    [
      activities,
      setSelectedBlocks,
      selectedBlocks,
      dispatch,
      setActivityColor,
      convertBlocksToTimeBlocks,
    ]
  );

  const onSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (title && selectedBlocks.length > 0) {
        const newActivityType: Omit<ActivityType, "id" | "archived"> = {
          title,
          color: activityColor || color,
        };

        dispatch(
          createActivityWithType({
            activityType: newActivityType,
            timeBlocks: convertBlocksToTimeBlocks(selectedBlocks),
          })
        );

        setSelectedBlocks([]);
        setActivityColor(null);
        setTitle("");
      }
    },
    [
      title,
      selectedBlocks,
      activityColor,
      color,
      convertBlocksToTimeBlocks,
      dispatch,
      setSelectedBlocks,
      setActivityColor,
    ]
  );

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <div className={styles.formGroup}>
        <ActivityInput
          value={title}
          onChange={setTitle}
          onSelect={handleActivitySelect}
        />
        <button type="submit" disabled={!title || !selectedBlocks.length}>
          <PlusCircledIcon />
        </button>
      </div>
    </form>
  );
};

export default ActivityForm;
