import { FC, useState, useCallback } from "react";
import clsx from "clsx";
import styles from "./EditableTitle.module.css";

interface EditableTitleProps {
  initialTitle: string;
  disabled?: boolean;
  onSave: (newTitle: string) => void;
}

const EditableTitle: FC<EditableTitleProps> = ({
  initialTitle,
  disabled,
  onSave,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(initialTitle);

  const handleSave = useCallback(() => {
    if (title.trim() !== initialTitle && title.trim() !== "") {
      onSave(title.trim());
    }
    setIsEditing(false);
  }, [title, initialTitle, onSave]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        handleSave();
      } else if (e.key === "Escape") {
        setTitle(initialTitle);
        setIsEditing(false);
      }
    },
    [handleSave, initialTitle]
  );

  if (isEditing) {
    return (
      <input
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        onBlur={handleSave}
        onKeyDown={handleKeyDown}
        className={styles.titleInput}
        disabled={disabled}
      />
    );
  }

  return (
    <span
      onClick={() => !disabled && setIsEditing(true)}
      className={clsx(styles.titleText, disabled && styles.disabled)}
    >
      {initialTitle}
    </span>
  );
};

export default EditableTitle;
