import { FC, useMemo, useState } from "react";
import * as Collapsible from "@radix-ui/react-collapsible";
import { Cross2Icon } from "@radix-ui/react-icons";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { deleteTimeBlock } from "store/activities";
import { setHoveredTimeBlock } from "store/hoveredLegend";
import selectTimeFormat from "store/selectors/selectTimeFormat";
import selectTodayActivities from "store/selectors/selectTodayActivities";
import { Activity, TimeBlock } from "types";
import {
  formatTime,
  calculateTimeBlockDuration,
  formatDuration,
} from "utils/time";
import styles from "./Legend.module.css";

const ChevronIcon: FC<{ className?: string }> = ({ className }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M2.5 4.5L6 8L9.5 4.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const TimeBlockList: FC<{ activity: Activity }> = ({ activity }) => {
  const dispatch = useDispatch();
  const timeFormat = useSelector(selectTimeFormat);

  const formatTimeBlock = (timeBlock: TimeBlock) => {
    const start = formatTime(timeBlock.start, timeFormat);
    const end = formatTime(timeBlock.end, timeFormat);
    const duration = formatDuration(calculateTimeBlockDuration(timeBlock));

    return {
      start,
      end,
      duration,
    };
  };

  const handleDeleteTimeBlock = (index: number) => {
    dispatch(
      deleteTimeBlock({ activityId: activity.id, timeBlockIndex: index })
    );
  };

  const handleTimeBlockHover = (timeBlock: TimeBlock | null) => {
    dispatch(
      setHoveredTimeBlock(
        timeBlock ? { ...timeBlock, activityId: activity.id } : null
      )
    );
  };

  return (
    <div className={styles.timeBlockList}>
      {activity.timeBlocks.map((timeBlock, index) => {
        const { start, end, duration } = formatTimeBlock(timeBlock);

        return (
          <div
            key={index}
            className={styles.timeBlockItem}
            onMouseEnter={() => handleTimeBlockHover(timeBlock)}
            onMouseLeave={() => handleTimeBlockHover(null)}
          >
            {`${start} - ${end}`}
            <div style={{ display: "flex", alignItems: "center" }}>
              {activity.timeBlocks.length > 1 ? (
                <span className={styles.badge}>{duration}</span>
              ) : null}
              <button
                className={styles.deleteButton}
                onClick={() => handleDeleteTimeBlock(index)}
              >
                <Cross2Icon fontSize="inherit" />
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const Legend: FC = () => {
  const activities = useSelector(selectTodayActivities);
  const [openActivity, setOpenActivity] = useState<string | null>(null);

  const uniqueActivities = useMemo(
    () => [...new Set(activities)],
    [activities]
  );

  const activityDurations = useMemo(() => {
    const durations: { [key: string]: number } = {};
    activities.forEach((activity) => {
      const totalDuration = activity.timeBlocks.reduce(
        (total, timeBlock) => total + calculateTimeBlockDuration(timeBlock),
        0
      );
      durations[activity.title] = totalDuration;
    });
    return durations;
  }, [activities]);

  return (
    <div className={styles.legend}>
      {uniqueActivities.map((activity) => (
        <Collapsible.Root
          key={activity.title}
          className={styles.legendItem}
          open={openActivity === activity.title}
          onOpenChange={(open) => setOpenActivity(open ? activity.title : null)}
        >
          <Collapsible.Trigger asChild>
            <div
              className={clsx(
                styles.legendHeader,
                openActivity === activity.title && styles.open
              )}
            >
              <div
                className={styles.colorDot}
                style={{ backgroundColor: activity.color }}
              />
              <span className={styles.activityTitle}>{activity.title}</span>
              <span className={styles.activityDuration}>
                {formatDuration(activityDurations[activity.title])}
              </span>
              <ChevronIcon
                className={`${styles.expandIcon} ${
                  openActivity === activity.title ? styles.expandIconOpen : ""
                }`}
              />
            </div>
          </Collapsible.Trigger>
          <Collapsible.Content className={styles.timeBlockListWrapper}>
            <TimeBlockList activity={activity} />
          </Collapsible.Content>
        </Collapsible.Root>
      ))}
    </div>
  );
};

export default Legend;
