import { FC, useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import Block from "components/Block";
import ExportMarkdownButton from "components/ExportMarkdownButton";
import { useSelectedBlocksContext } from "components/SelectedBlocksContext";
import { isWithinInterval, parseISO } from "date-fns";
import useActivityColor from "hooks/useActivityColor";
import { useSelector } from "react-redux";
import selectActivitiesWithType, {
  ActivityWithType,
} from "store/selectors/selectActivitiesWithType";
import selectBlockSizeMinutes from "store/selectors/selectBlockSizeMinutes";
import selectHoveredTimeBlock from "store/selectors/selectHoveredTimeBlock";
import {
  mapActivitiesToBlocks,
  getActivityBlocks,
  convertBlockIndexToTime,
} from "utils/time";
import styles from "./Container.module.css";

const Container: FC = () => {
  const activitiesWithType = useSelector(selectActivitiesWithType);
  const blockSizeMinutes = useSelector(selectBlockSizeMinutes);
  const hoveredTimeBlock = useSelector(selectHoveredTimeBlock);

  const { selectedBlocks, setSelectedBlocks, activityColor, setActivityColor } =
    useSelectedBlocksContext();

  const { blocks, currentBlock, oneThird, twoThirds, blocksPerDay } =
    useMemo(() => {
      const blocksPerDay = (24 * 60) / blockSizeMinutes;
      const blocks = Array.from({ length: blocksPerDay }, (_, i) => i);
      const currentBlock = Math.floor(
        blocksPerDay * (new Date().getHours() / 24)
      );
      const oneThird = Math.floor(blocksPerDay * (1 / 3));
      const twoThirds = Math.floor(blocksPerDay * (2 / 3));

      return { blocks, currentBlock, oneThird, twoThirds, blocksPerDay };
    }, [blockSizeMinutes]);

  const [activityBlockMap, setActivityBlockMap] = useState<
    Map<number, ActivityWithType>
  >(new Map());
  const [previewHoverIndex, setPreviewHoverIndex] = useState<number | null>(
    null
  );

  const color = useActivityColor();

  useEffect(() => {
    const blockMap = mapActivitiesToBlocks(
      activitiesWithType,
      blockSizeMinutes
    );
    setActivityBlockMap(blockMap);
  }, [activitiesWithType, blockSizeMinutes]);

  const handleBlockClick = useCallback(
    (index: number, shiftKey: boolean) => {
      let updatedSelectedBlocks: number[];

      if (!shiftKey) {
        setPreviewHoverIndex(null);
      }

      if (shiftKey && selectedBlocks.length > 0) {
        const lastSelectedBlock = selectedBlocks[selectedBlocks.length - 1];
        const startIndex = Math.min(lastSelectedBlock, index);
        const endIndex = Math.max(lastSelectedBlock, index);
        updatedSelectedBlocks = Array.from(
          { length: endIndex - startIndex + 1 },
          (_, i) => startIndex + i
        );
      } else if (selectedBlocks.includes(index)) {
        updatedSelectedBlocks = selectedBlocks.filter(
          (block) => block !== index
        );
      } else {
        updatedSelectedBlocks = [...selectedBlocks, index];
      }

      if (selectedBlocks.length === 0 && updatedSelectedBlocks.length > 0) {
        setActivityColor(color);
      }

      setSelectedBlocks(updatedSelectedBlocks);
    },
    [color, selectedBlocks, setActivityColor, setSelectedBlocks]
  );

  const handleBlockHover = useCallback((index: number, shiftKey: boolean) => {
    if (!shiftKey) {
      return;
    }

    setPreviewHoverIndex(index);
  }, []);

  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setSelectedBlocks([]);
        setPreviewHoverIndex(null);
        setActivityColor(null);
      } else if (event.key === "Shift" && previewHoverIndex !== null) {
        setPreviewHoverIndex(null);
      }
    },
    [previewHoverIndex, setActivityColor, setSelectedBlocks]
  );

  useEffect(() => {
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyUp]);

  const getActivityForBlock = useCallback(
    (index: number) => {
      return activityBlockMap.get(index);
    },
    [activityBlockMap]
  );

  const getBlockIndicesForActivity = useCallback(
    (activity: ActivityWithType): number[] => {
      return getActivityBlocks(activity, blockSizeMinutes);
    },
    [blockSizeMinutes]
  );

  useEffect(() => {
    const blockMap = mapActivitiesToBlocks(
      activitiesWithType,
      blockSizeMinutes
    );
    setActivityBlockMap(blockMap);
  }, [activitiesWithType, blockSizeMinutes]);

  const isBlockHovered = useCallback(
    (index: number, activity: ActivityWithType | undefined): boolean => {
      if (!hoveredTimeBlock || !activity) return false;

      const blockStartTime = parseISO(
        convertBlockIndexToTime(index, blockSizeMinutes)
      );
      const blockEndTime = parseISO(
        convertBlockIndexToTime(index + 1, blockSizeMinutes)
      );

      const hoveredStartTime = parseISO(hoveredTimeBlock.start);
      const hoveredEndTime = parseISO(hoveredTimeBlock.end);

      return (
        hoveredTimeBlock.activityId === activity.id &&
        isWithinInterval(blockStartTime, {
          start: hoveredStartTime,
          end: hoveredEndTime,
        }) &&
        isWithinInterval(blockEndTime, {
          start: hoveredStartTime,
          end: hoveredEndTime,
        })
      );
    },
    [hoveredTimeBlock, blockSizeMinutes]
  );

  const renderBlockGroup = (start: number, end: number) => (
    <div
      className={clsx(
        styles.blockGroup,
        currentBlock >= start && currentBlock < end && styles.currentBlockGroup
      )}
    >
      {blocks.slice(start, end).map((index) => {
        const activity = getActivityForBlock(index);
        const isSelected = selectedBlocks.includes(index);
        const isPreviewHover =
          previewHoverIndex !== null &&
          ((previewHoverIndex > selectedBlocks[selectedBlocks.length - 1] &&
            index <= previewHoverIndex &&
            index > selectedBlocks[selectedBlocks.length - 1]) ||
            (previewHoverIndex < selectedBlocks[selectedBlocks.length - 1] &&
              index >= previewHoverIndex &&
              index < selectedBlocks[selectedBlocks.length - 1]));

        return (
          <Block
            key={`${blockSizeMinutes}-${index}`}
            index={index}
            color={activityColor}
            activity={activity}
            isSelected={isSelected}
            isPreviewHover={isPreviewHover}
            onClick={(shiftKey) => handleBlockClick(index, shiftKey)}
            onHover={(shiftKey) => handleBlockHover(index, shiftKey)}
            blockIndices={activity ? getBlockIndicesForActivity(activity) : []}
            isHoveredLegend={isBlockHovered(index, activity)}
          />
        );
      })}
    </div>
  );

  return (
    <div className={styles.container}>
      {renderBlockGroup(0, oneThird)}
      {renderBlockGroup(oneThird, twoThirds)}
      {renderBlockGroup(twoThirds, blocksPerDay)}
      <ExportMarkdownButton />
    </div>
  );
};

export default Container;
